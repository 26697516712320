/* eslint-disable */
export const ProductType = 'RPDPLACEHOLDER';

export enum ProductPermissions {
  PLACEHOLDER = 'PLACEHOLDER',
}



export const ReportParameterMappings: any = {

  dailyReportParameterMapping: {
    'EVP': 'UserPrivilegeEVP',
    'SVP': 'UserPrivilegeSVP',
    'REGION': 'UserPrivilegeREGION',
    'MARKET': 'UserPrivilegeMARKET',
    'STORE_NUMBER': 'StoreQuerySTOREID',
    'TodayDefault': 'CalendarDate'
  },
  dapReportParameterMapping: {
    'EVP': 'EVP',
    'SVP': 'SVP',
    'REGION': 'Region',
    'MARKET': 'District',
    'STORE_NUMBER': 'StoreNumber',
    'Date1': 'StartDate'
  },
  inventoryRecapReportParameterMapping: {
    'EVP': 'EVP',
    'SVP': 'SVP',
    'REGION': 'Region',
    'MARKET': 'District',
    'STORE_NUMBER': 'StoreNumber',
    'TodayDefault': 'CalendarDate'
  },
  itemBeingServiceReportParameterMapping: {
    'EVP': 'EVP',
    'SVP': 'SVP',
    'REGION': 'Region',
    'MARKET': 'District',
    'STORE_NUMBER': 'StoreNumber',
    'TodayDefault': 'CalendarDate'
  },
  salesPersonReportParameterMapping: {
    'EVP': 'UserIDEVP',
    'SVP': 'UserIDSVP',
    'REGION': 'UserIDREGION',
    'MARKET': 'UserIDMARKET',
    'STORE_NUMBER': 'SalesPersonRecapSTORENUMBER',
    'Date': 'EndDate'
  },
  skipStolenReportParameterMapping: {
    'EVP': 'EVP',
    'SVP': 'SVP',
    'REGION': 'Region',
    'MARKET': 'District',
    'STORE_NUMBER': 'StoreNumber',
    'TodayDefault': 'EndDate'
  },

  exceptionAuditTrailReportParameterMapping: {
    'EVP': 'EVP',
    'SVP': 'SVP',
    'REGION': 'Region',
    'MARKET': 'District',
    'STORE_NUMBER': 'StoreNumber',
    'TodayDefault': 'EndDate',
    'MODULE': 'ModuleType',
    'EMPLOYEE': 'Employee'
  },
  pettyCashAnalysisDetailReportParameterMapping: {
    'EVP': 'EVP',
    'SVP': 'SVP',
    'REGION': 'REGION',
    'MARKET': 'MARKET',
    'STORE_NUMBER': 'STORENUMBER',
    'DAY_DATE': 'CalendarDate',
    'EMPLOYEE': 'PettyCashAnalysisdetailEMPLOYEE'
  },
  pettyCashAnalysisSummaryReportParameterMapping: {
    'EVP': 'EVP',
    'SVP': 'SVP',
    'REGION': 'REGION',
    'MARKET': 'MARKET',
    'STORE_NUMBER': 'StoreNumber',
    'TodayDefault': 'StartDate'
  },
  receiptAuditTrailReportParameterMapping: {
    'EVP': 'EVP',
    'SVP': 'SVP',
    'REGION': 'Region',
    'MARKET': 'District',
    'STORE_NUMBER': 'StoreNumber',
    'DAY_DATE': 'EndDate',
    "EMPLOYEENAME": 'ReceiptAuditTrialEMPLOYEENAME',
    "FTE_Label": 'FreeTimeExtension',
    "Receipt_Label": "Receipt",
    "PAYORIGIN": "ReceiptAuditTrialPAYORIGIN",

  },
}

